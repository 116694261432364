.pagination-controls {
  margin-left: 10px;
}

.paginationControls form.form-inline {
  display: flex;
}

.pagination > li:first-child > button {
  margin-left: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.pagination > .disabled > span,
.pagination > .disabled > span:hover,
.pagination > .disabled > span:focus,
.pagination > .disabled > button,
.pagination > .disabled > button:hover,
.pagination > .disabled > button:focus,
.pagination > .disabled > a,
.pagination > .disabled > a:hover,
.pagination > .disabled > a:focus {
  color: var(--text-pagination-color);
  background-color: var(--bg-pagination-disabled-color);
  border-color: var(--border-pagination-color);
}

.pagination > li > button {
  position: relative;
  float: left;
  padding: 6px 12px;
  margin-left: -1px !important;
  line-height: 1.42857143;
  text-decoration: none;
  border: 1px solid var(--ui-white);
}

.pagination > li > a,
.pagination > li > button,
.pagination > li > span {
  background-color: var(--bg-pagination-span-color);
  border-color: var(--border-pagination-span-color);
  color: var(--text-pagination-span-color) !important;
}

.pagination > li > a:hover,
.pagination > li > button:hover,
.pagination > li > span:hover,
.pagination > li > a:focus,
.pagination > li > button:focus,
.pagination > li > span:focus {
  background-color: var(--bg-pagination-hover-color);
  border-color: var(--border-pagination-hover-color);
  color: var(--text-pagination-span-hover-color);
  border-radius: 8px;
}

.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > button,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > button:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus,
.pagination > .active > button:focus {
  @apply text-blue-7;
  z-index: 3;
  cursor: default;
  /* background-color: var(--text-pagination-span-color); */
  background-color: var(--bg-pagination-color);
  border-radius: 8px;
  color: var(--text-pagination-span-hover-color) !important;
  border-color: var(--border-pagination-span-color);
}
