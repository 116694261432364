:global(portainer-tooltip) {
  @apply inline-flex;
}

.tooltip {
  background-color: var(--bg-tooltip-color) !important;
  color: var(--text-tooltip-color) !important;
  border-radius: 10px !important;
  box-shadow:
    0 2px 4px 0 rgba(34, 36, 38, 0.12),
    0 2px 10px 0 rgba(34, 36, 38, 0.15) !important;
  max-width: 400px;
  min-width: 50px;
  font-size: 1rem !important;
  font-weight: 400;
  text-align: left;
  width: max-content;
}

.tooltip-container {
  line-height: 18px;
  padding: 8px 10px !important;
  font-size: 12px !important;
  user-select: none;
}

.tooltip-centered .tooltip-container {
  text-align: center;
  user-select: none;
}

.tooltip-message a {
  color: var(--blue-15) !important;
}
