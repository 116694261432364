.modal-header {
  margin-bottom: 10px;
  padding: 0px;
  border-bottom: none;
}

.background-error {
  padding-top: 55px;
  background-image: url(~assets/images/icon-error.svg);
  background-repeat: no-repeat;
  background-position: top left;
}

.background-warning {
  padding-top: 55px;
  background-image: url(~assets/images/icon-warning.svg);
  background-repeat: no-repeat;
  background-position: top left;
}
