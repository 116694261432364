.checkbox {
  padding-left: 0.5rem;
}

.checkbox-list {
  max-height: 200px;
  overflow-y: auto;
  background-color: var(--white-color);
  border: 0;
  border-radius: 4px;
}

:root[theme='dark'] .checkbox-list,
:root[theme='highcontrast'] .checkbox-list {
  background-color: var(--bg-modal-content-color);
}
