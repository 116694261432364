.root input {
  display: none;
}

.root label {
  @apply border border-solid;
  @apply border-gray-5 bg-gray-2 text-black;
  @apply th-dark:border-gray-neutral-8 th-dark:bg-gray-iron-10 th-dark:text-white;
  @apply th-highcontrast:text-white;

  font-weight: normal;
  font-size: 12px;
  display: block;
  border-radius: 8px;

  padding: 20px 15px;
  text-align: left;
  box-shadow: var(--shadow-boxselector-color);
  position: relative;
  margin-bottom: 0;

  text-align: left;
  height: 100%;
}

/* not disabled */
.root input:not(:disabled) ~ label {
  @apply bg-gray-2;
  @apply th-dark:bg-gray-iron-10;
  @apply th-highcontrast:bg-black;

  box-shadow: none;
  cursor: pointer;
}

/* disabled */
.root input:disabled + label {
  @apply bg-white;
  @apply th-dark:bg-gray-7;
  @apply th-highcontrast:bg-black;
  filter: opacity(0.3) grayscale(1);

  cursor: not-allowed;
}

.root input:checked + label {
  @apply border-blue-6 bg-blue-2;
  @apply th-dark:border-blue-7 th-dark:bg-blue-10;
  @apply th-highcontrast:border-blue-7 th-highcontrast:bg-blue-10;

  box-shadow: none;
}
