.close {
  color: var(--button-close-color);
  opacity: var(--button-opacity);

  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
  appearance: none;

  font-size: 21px;
  font-weight: bold;
  line-height: 1;
  text-shadow: 0 1px 0 #fff;
  filter: alpha(opacity=20);
}

.close:hover,
.close:focus {
  color: var(--button-close-color);
  opacity: var(--button-opacity-hover);
}
