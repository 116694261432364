.sort-by-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.sort-by-element {
  display: inline-block;
}

.sort-button {
  --text-color: var(--grey-6);
  background-color: var(--bg-sortbutton-color);
  color: var(--text-color);
  border: 1px solid var(--border-sortbutton);
  display: inline-block;
  padding: 8px 10px;
  border-radius: 5px;
}

:global([theme='dark']) .sort-button,
:global([theme='highcontrast']) .sort-button {
  --text-color: var(--white-color);
}
