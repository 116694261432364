.service-form .form-group {
  vertical-align: top;
}

.service-form .form-group .input-group {
  width: 100%;
}

.service-form .clear-both {
  clear: both;
}

.service-form .form-group {
  vertical-align: top;
}

.service-form .form-group .input-group {
  width: 100%;
}

.service-form .clear-both {
  clear: both;
}
