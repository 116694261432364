.secondary-heading {
  background-color: transparent;
}

.secondary-body {
  background-color: transparent;
}

.datatable-wide {
  width: 55px;
}

.published-url-container {
  display: grid;
  grid-template-columns: 1fr 1fr 3fr;
  padding-top: 10px;
  padding-bottom: 5px;
}

.publish-url-link {
  width: min-content;
}
