.stepper-wrapper {
  width: 60%;
  margin-top: auto;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  margin-left: 50px;
}
.step-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex: 1;
}

.step-wrapper::before {
  position: absolute;
  content: '';
  width: 100%;
  top: 15px;
  left: -100%;
  z-index: 2;
  border-bottom: 3px solid var(--border-stepper-color);
}

.step-wrapper::after {
  position: absolute;
  content: '';
  border-bottom: 3px solid var(--border-stepper-color);
  width: 100%;
  top: 15px;
  left: 0;
  z-index: 2;
}

.step .step-name {
  position: absolute;
  bottom: -25px;
  min-width: max-content;
}

.step-wrapper .step {
  position: relative;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  /* background: var(--ui-white); */
  background: var(--bg-stepper-color);
  margin-bottom: 6px;
  border: 1px solid var(--ui-gray-6);
}

.step-wrapper.active {
  font-weight: bold;
  content: none;
}

.step-wrapper.active .step {
  background: var(--bg-stepper-active-color);
  border: 2px solid var(--ui-blue-8);
}

.step-wrapper.active .step-counter {
  color: var(--text-stepper-active-color);
}

.step-wrapper.completed .step {
  background: var(--bg-stepper-active-color);
  border: 2px solid var(--ui-blue-8);
}

.step-wrapper.completed .step-counter {
  color: var(--text-stepper-active-color);
}

.step-wrapper.completed::after {
  position: absolute;
  content: '';
  border-bottom: 3px solid var(--ui-blue-8);
  width: 100%;
  top: 15px;
  left: 0;
  z-index: 3;
}

.step-wrapper:first-child::before {
  content: none;
}

.step-wrapper:last-child::after {
  content: none;
}
